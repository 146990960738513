<template>
    <div>
        <h2>ช่องทางการจัดส่ง > {{ $store.getters['app/GET_APP_MODE'] == 'create' ? "เพิ่ม" : "แก้ไข"}}ช่องทางการจัดส่ง</h2>
        <v-form v-model="isFormValid">
          <v-card class="mt-4">
            <div class="pa-8">
              <v-row>
                <v-col cols="12" md="6">
                  <v-select
                    v-model="formData.courier_code"
                    :items="courierList"
                    label="วิธีการจัดส่ง"
                    item-text="name"
                    item-value="id"
                    outlined
                    dense
                    :rules="[validators.required]"
                    :disabled="$store.getters['app/GET_APP_MODE'] != 'create'"
                    hide-details="auto"
                  >
                    <!--
                    <template v-slot:item = "{ item }">
                      
                      {{ item.courier_name }} {{ item.note ? '(' + item.note + ')' : '' }}
                    </template> 

                    <template v-slot:selection = "{ item }">
                      {{ item.courier_name }} {{ item.note ? '(' + item.note + ')' : '' }}
                    </template>
                    -->
                  </v-select>                  
                </v-col>
              </v-row>
              <v-row v-if="formData.courier_code != 'manual'">
                <v-col>
                  <div class="error--text font-weight-bold">*** เป็นการเชื่อมต่อกับระบบของ ShipPop ท่านสามารถเชื่อมต่อได้ ที่นี่</div>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="formData.name"
                    outlined
                    label="ชื่อช่องทาง *"                               
                    :rules="[validators.required, validators.maxLengthValidator(formData.name, 50)]"
                    hide-details="auto"          
                    dense  
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model.number="formData.default_cost"
                    outlined
                    label="ค่าจัดส่ง *"                               
                    :rules="[validators.required, validators.minValueValidator(formData.default_cost, 0)]"
                    hide-details="auto"          
                    dense  
                  ></v-text-field>
                </v-col>
              </v-row>

              <template v-if="formData.courier_code != 'manual'">
                <v-row>
                  <v-col cols="12" md="6" class="black--text">
                    ข้อมูลผู้ส่ง
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="formData.address"
                      outlined
                      label="ที่อยู่ *"                               
                      :rules="[validators.required]"
                      hide-details="auto"          
                      dense  
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                      v-model="formData.tel"
                      outlined
                      label="เบอร์ *"                               
                      :rules="[validators.required]"
                      hide-details="auto"          
                      dense  
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <VThailandAddressAutoComplete 
                        v-model="formData.city" 
                        placeholder="แขวง/ตำบล *" 
                        name="city" type="district" 
                        hide-details="auto" 
                        @select="setAddress"
                        dense
                        :rules="[validators.required]"
                        />
                    </v-col>
                    <v-col>
                        <VThailandAddressAutoComplete 
                        v-model="formData.county" 
                        placeholder="เขต/อำเภอ *" 
                        name="county" 
                        type="amphoe" 
                        hide-details="auto" 
                        @select="setAddress"
                        dense
                        :rules="[validators.required]"
                        />
                    </v-col>                    
                </v-row>
                <v-row>
                    <v-col>
                        <VThailandAddressAutoComplete 
                        v-model="formData.province" 
                        placeholder="จังหวัด *" 
                        name="province" 
                        type="province" 
                        hide-details="auto" 
                        @select="setAddress"
                        dense
                        :rules="[validators.required]"
                        />
                    </v-col>
                    <v-col>
                        <VThailandAddressAutoComplete 
                        v-model="formData.postcode" 
                        placeholder="เลขไปรษณีย์ *" 
                        name="postcode" 
                        type="zipcode" 
                        hide-details="auto" 
                        @select="setAddress"
                        dense
                        :rules="[validators.required]"
                        />
                    </v-col>                    
                </v-row>
              </template>
            </div>

            <v-card-actions>              
              <v-btn
                color="primary"               
                large          
                :disabled="!isFormValid"
                :loading="sendingData"
                @click="submitForm"
                v-if="$store.getters['app/GET_APP_MODE'] != 'read'"
              >
                {{ $store.getters['app/GET_APP_MODE'] == 'create' ? "สร้าง" : "แก้ไข"}}
              </v-btn>

              <v-btn
                color="secondary"               
                outlined                 
                large
                :disabled="sendingData"
                @click="$router.go(-1)"
              >
                กลับ
              </v-btn>                
            </v-card-actions>
          </v-card>          
        </v-form>
    </div>
</template>
<script>
import { maxLengthValidator, minValueValidator, required } from '@/@core/utils/validation'
import { asyncGet, asyncPost, asyncUpdate } from '@/helpers/asyncAxios'
export default {
  data() {
    return {
      isFormValid: false,
      sendingData: false,
      deliveryProvider: [],
      validators: {
        required,
        maxLengthValidator,
        minValueValidator,
      },
      courierList: [],
      formData: {
        courier_code: 'manual',
        name: null,
        default_cost: 0,
        address: '',
        city: '',
        county: '',
        province: '',
        postcode: '',
        tel: '',
      },
    }
  },
  async created() {
    this.$store.commit('app/SET_GLOBAL_LOADING', true)

    const courierList = await asyncGet('/setting/delivery/courierList')

    this.courierList = [
      {
        id: 'manual',
        name: 'อื่น ๆ ทั่วไป',
      },
      ...courierList.map(item => {
        return {
          id: item.courier_code,
          name: item.note ? item.courier_name + ' (' + item.note + ')' : item.courier_name,
        }
      }),
    ]

    const delivery_id = this.$route.params.delivery_id

    if (delivery_id == 'create') {
      this.$store.commit('app/SET_APP_MODE', 'create')
      this.$store.commit('app/SET_GLOBAL_LOADING', false)
      return
    } else if (this.$store.getters['auth/GET_ABILITY'].can('update', 'Setting'))
      this.$store.commit('app/SET_APP_MODE', 'edit')
    else this.$store.commit('app/SET_APP_MODE', 'read')

    try {
      const { courier_code, name, default_cost, address, city, county, province, postcode, tel } = await asyncGet(
        '/setting/delivery/' + delivery_id,
      )

      this.formData = {
        courier_code,
        name,
        default_cost,
        address,
        city,
        county,
        province,
        postcode,
        tel,
      }
    } catch (error) {
      this.$root.showCommonDialog('มีปัญหา', error)
    }
    this.$store.commit('app/SET_GLOBAL_LOADING', false)
  },
  methods: {
    async submitForm() {
      this.sendingData = true
      try {
        const delivery_id = this.$route.params.delivery_id

        if (delivery_id == 'create') await asyncPost('/setting/delivery', this.formData)
        else {
          await asyncUpdate('/setting/delivery/' + delivery_id, this.formData)
        }

        this.$router.push({ name: 'delivery' })
      } catch (error) {
        this.$root.showCommonDialog('มีปัญหา', error)
      }

      this.sendingData = false
    },
    setAddress(address) {
      this.formData.city = address.district
      this.formData.county = address.amphoe
      this.formData.province = address.province
      this.formData.postcode = address.zipcode
    },
  },
  components: {},
}
</script>